<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t('message.add_movement') }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" size="small" class="mr-1" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save') }}</el-button>
              <el-button type="warning" icon="el-icon-close" size="small" @click="resetForm('form')">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form ref="form" :model="form" :rules="rules" class="aticler_m" size="small">
        <el-row :gutter="25">
          <el-col :sm="12">
            <el-form-item :label="$t('message.main_warehouses')" prop="mainwarehouse_id">
               <mainwarehouse v-on:clear-x="afterClosed()" v-model="form.mainwarehouse_id" :mainwarehouse_id="form.mainwarehouse_id" size="mini"></mainwarehouse>
            </el-form-item>
          </el-col>
          <!-- end col -->
          <el-col :sm="12">
            <el-form-item v-if="form.mainwarehouse_id" :label="$t('message.warehouse_movement')" prop="warehouse_id">
              <warehouse v-model="form.warehouse_id" :mainwarehouse_id="form.mainwarehouse_id" size="mini"></warehouse>
            </el-form-item>
            <el-form-item v-else :label="$t('message.warehouse_movement')" prop="warehouse_id">
                  <el-select size="mini" v-model="value" disabled :placeholder="$t('message.warehouse_movement')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
        <!-- end el-row -->

        <el-card class="box-card mb-4">
          <div>
              <el-table border :data="items" :row-class-name="tableRowClassName">
                <el-table-column label="#" width="30">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope">{{ scope.row.product.name }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.available')">
                  <template slot-scope="scope">{{ scope.row.available | formatMoney }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.quantity" @input="correctNumber(scope.row, 'quantity')"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.actions')">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeWriteOffItem(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
          </div>

          <el-row :gutter="25">
            <el-col :span="11"  class="mt-3">
              <el-form-item v-if="form.mainwarehouse_id" :label="$t('message.products')">
                <products @append="append" v-model="form.product_id" :product_id="form.product_id"></products>
              </el-form-item>
              <el-form-item v-else :label="$t('message.products')">
                  <el-select v-model="value" disabled :placeholder="$t('message.products')">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>  
    </div>
  </div>
</template>
<script>
import mainwarehouse from '@/components/selects/mainwarehouse';
import warehouse from '@/components/selects/warehouse';
import { mapGetters, mapActions } from 'vuex';
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import products from "@/components/selects/products";
import form from "@/utils/mixins/form";
import {formatMoney} from "../../../filters";
export default {
    mixins: [
    form,
    drawer,
    drawerChild
  ],
   components: {
    warehouse,
    mainwarehouse,
    products
  },
  data() {
    return {
      loadingButton: false,
      options: [],
      value: '',
      items: []
    };
  },
    computed: {
    ...mapGetters({
      columns: 'movings/columns',
      model: 'movings/model',
      rules: 'movings/rules'
    })
  },
    methods: {
      formatMoney,
    ...mapActions({
      save: "movings/store",
      update: "movings/update",
      show: "movings/show",
      empty: "movings/empty",
      showProductRemainder: 'movings/showrem',
    }),
    beforeClose(done) {
      alert('asdasd');
      this.items = [];
      done();
    },
    afterClosed() {
      this.items = [];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
    },
    append(product) {
      const item = this.items.find((item) => item.product_id == product.id);
      this.loadingItems = true;
      const payload = {product_id: product.id, warehouse_id: this.form.mainwarehouse_id};
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.items.push({ 
              product_id: product.id,
              available: product.available ? product.available : 0,
              quantity: 0,
              product: {
                name: product.name,
              },
            });
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    afterOpened(){
        this.form = JSON.parse( JSON.stringify(this.model) );
    },
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    submit(close = true) {
      this.$refs['form'].validate((valid) => {
          if (valid){
            const payload = {
              mainwarehouse_id: this.form.mainwarehouse_id,
              warehouse_id: this.form.warehouse_id,
              items: this.items
            };

            this.save(payload)
              .then((response) => {
                this.$alert(response.message);
                if (close == true) {
                    this.close();
                }
              })
              .catch(error => {
                this.$alert(error.message);
              });
          }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.available == 0) {
        return 'warning-row';
      }
    },
    correctNumber(obj, propertyName) {
      let unformattedValue = obj[propertyName].toString().replace(/\s/g, '');
      let formattedValue = unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

      this.$set(obj, propertyName, formattedValue);
    },
    strToInt(val) {
      if (typeof(val) === 'string') {
        return parseFloat(val.replace(/\s/g, ''));
      }
      return val;
    }
  }
};
</script>
<style>
.el-table tr.warning-row {
  background-color: #e6a23c;
}
</style>